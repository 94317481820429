import React from 'react';

import './App.css';
import SuperSix from './components/presentation/SuperSix.js';

function App() {
  return (
    <div className="App">
        <SuperSix />
    </div>
  );
}

export default App;
